import { decodeHtml } from '~/lib/utilities'
import {
  Menu,
  SiteBanner,
  Banner,
  CompanyService,
  CmsImage,
  ContentBlock,
} from '~/types/content'

const convertTitle = (data: any) =>
  data.LinkTitle || data.Title || data.Url || data.Name

const convertUrl = (data: any) =>
  data.PathUrl ? `${data.PathUrl}/${data.Url}` : data.Url

export const convertMenu = (data: any): Menu => ({
  name: convertTitle(data),
  key: data.Name,
  url: convertUrl(data),
  alternateUrl: data.AlternateUrl,
  children: convertMenus(data.Children),
})

export const convertMenus = (data?: any[] | null): Menu[] => {
  if (!data) return []
  return data.map((item: any) => convertMenu(item))
}

export const convertImage = (data: any): CmsImage => ({
  src: data.CMSUrl || data.FilePath,
  alt: data.Title || data.Name,
  alternateUrl: data.AlternateUrl || data.TargetUrl,
  type: 'image'
})

export const convertBanner = (data: any): Banner => ({
  title: convertTitle(data),
  name: data.Name,
  url: convertUrl(data),
  heading: data.Heading,
  description: data.Text ? decodeHtml(data.Text) : '',
  linkTitle: data.LinkTitle,
  alternateUrl: data.AlternateUrl,
  img: data.Images?.[0]?.FilePath,
})

export const convertSiteBanner = (data: any): SiteBanner => ({
  id: data.ID,
  title: data.Title,
  text: data.Text ? decodeHtml(data.Text) : '',
  cssBackgroundColor: data.CssBackgroundColor,
  cssTextColor: data.CssTextColor,
  alternateUrl: data.AlternateUrl,
  linkTitle: data.LinkTitle,
  dismissible: data.Dismissible,
  cssClass: data.cssClass,
  showCart: data.ShowCart,
  showCheckout: data.ShowCheckout,
  showContent: data.ShowContent,
  showHomepage: data.ShowHomepage,
  showProduct: data.ShowProduct,
  showProductList: data.ShowProductList,
  showService: data.ShowService,
  showSiteWide: data.ShowSiteWide,
})

export const BaseCompanyServicesMap = new Map<string, string>([
  ['VakavondenEnDemodagen', 'Vakavonden en demodagen'],
  ['OpMaatZagen', 'Op maat zagen'],
  ['AanhangwagenVerhuur', 'Aanhangwagen verhuur'],
  ['Laadhulp', 'Laadhulp'],
  ['Verfmengen', 'Verfmengen'],
  ['GereedschapSlijpen', 'Gereedschap slijpen'],
  ['Afvalcontainers', 'Afvalcontainers'],
  ['SiloService', 'Silo-service (kant-en-klare vloermortel)'],
])

const CompanyServiceFieldsSet = new Set([
  ...BaseCompanyServicesMap.keys(),
  'CustomField1',
  'CustomField2',
  'CustomField3',
])

export const convertCompanyServices = (
  data: Record<string, any>
): CompanyService[] => {
  const services: CompanyService[] = []
  Object.keys(data).forEach((key) => {
    if (!CompanyServiceFieldsSet.has(key)) return

    if (BaseCompanyServicesMap.has(key)) {
      if (data[key] !== '1') return
      services.push({
        field: key,
        name: BaseCompanyServicesMap.get(key) as string,
      })
    } else {
      services.push({
        field: key,
        name: data[key],
      })
    }
  })

  return services
}

const convertBlockData = (data: any, id: string) => {
  if (data) {
    let result = {}
    try {
      result = JSON.parse(data);
    } catch {
      // eslint-disable-next-line no-console
      console.log(`JSON parse failed for block ${id}`);
    }
    return result
  }
}

export const convertBlock = (data: any): ContentBlock => ({
  id: data.ID,
  title: convertTitle(data),
  content: data.Text ? decodeHtml(data.Text) : '',
  type: data.Type,
  typeId: data.TypeID,
  data: data.Data ? convertBlockData(data.Data, data.ID) : {}
})

export const convertBlocks = (data?: any[] | null): ContentBlock[] => {
  if (!data) return []
  return data.filter((item: any) => !item.IsHidden)?.map((i) => convertBlock(i))
}
