import { encodeName, decodeHtml } from '~/lib/utilities'
import { Brand, BrandImageType } from '~/types/brand'

export const isShowBrand = (brand: Brand): boolean => {
  const filterOutBrands = ['geen merk']
  return !!(
    brand.name && !filterOutBrands.includes(brand.name.toLocaleLowerCase())
  )
}

export const convertBrand = (data: any, extraFields?: any): Brand => {
  const result: Brand = {
    id: data.Id,
    name: data.Description,
    url: data.Url || encodeName(data.Description),
    img: data.Images?.find((imgItem: any) => imgItem.Type === BrandImageType.Front)
      ?.Url,
  }

  const brandMemos = extraFields?.BrandMemos
  if (brandMemos?.length) {
    const memo = brandMemos.find(
      (memo: any) => memo.MemoType === 'InternetMemo'
    )
    result.description = memo?.Text ? decodeHtml(memo.Text) : ''
  }

  return result
}

export const convertBrands = (data: any, isFilter: boolean = true): Brand[] => {
  const brands = data.map((item: any) => convertBrand(item))
  if (isFilter)
    return brands.filter((brand: Brand) => {
      return isShowBrand(brand)
    })
  return brands
}
