export const convertWishlist = (data: any) => {
  return data.map((item: any) => convertWishlistItem(item))
}

export const convertWishlistItem = (data: any) => {
  return {
    count: data.Count,
    title: data.Title,
    products: data.Products,
  }
}
