import { Dictionary } from 'ramda'
import { convertProduct } from './product'
import {
  Transaction,
  TransactionStore,
  TransactionPayment,
  TransactionRecord,
} from '~/types/transactions'

export const convertTransactionStore = (data: any): TransactionStore => ({
  name: data.Name,
  branchNumber: data.BranchNumber,
})

export const convertTransactionRecord = (
  data: any,
  extraFields?: Dictionary<any>
): TransactionRecord => ({
  id: data.Id,
  amountInclTax: data.AmountInclTax,
  amountExclTax: data.AmountExclTax,
  discountAmount: data.DiscountAmount,
  taxAmount: data.TaxAmount,
  productQuantity: data.ProductQuantity,
  orderQuantity: data.OrderQuantity,
  product: convertProduct(data.Product, {
    url: extraFields?.productUrl,
    inMainTree: extraFields?.productInMainTree,
  }),
})

export const convertTransactionRecords = (
  records: Array<any>,
  productUrls?: Dictionary<string>,
  productsInMainTree?: Dictionary<boolean>
): TransactionRecord[] => {
  return records.map((record) =>
    convertTransactionRecord(record, {
      productUrl: productUrls?.[record?.Product?.Id],
      productInMainTree: productsInMainTree?.[record?.Product?.Id],
    })
  )
}

export const convertTransactionPayment = (data: any): TransactionPayment => ({
  id: data.Id,
  amount: data.Amount,
  paymentMethodId: data.PaymentMethodId,
})

export const convertTransactionPayments = (data: any): TransactionPayment[] => {
  return data.map((paymentItem) => convertTransactionPayment(paymentItem))
}

export const convertTransaction = (
  data: any,
  productUrls?: Dictionary<string>,
  productsInMainTree?: Dictionary<boolean>
): Transaction => {
  const transaction: Transaction = {
    id: data.Id,
    number: data.Number,
    date: new Date(data.Date),
    storeId: data.StoreId,
    customerId: data.CustomerId,
    amountInclTax: data.AmountInclTax,
    taxAmount: data.TaxAmount,
    amountExclTax: data.AmountExclTax,
    discountAmount: data.DiscountAmount,
    payableAmount: data.PayableAmount,
    receivedAmount: data.ReceivedAmount,
    bonusPoints: data.BonusPoints,
    sessionIdCreated: data.SessionIdCreated,
    type: data.Type,
    state: data.State,
    cashDrawerId: data.CashDrawerId,

    records: convertTransactionRecords(
      data.Records ?? [],
      productUrls,
      productsInMainTree
    ),
    payments: convertTransactionPayments(data.Payments ?? []),
    store: convertTransactionStore(data.Store ?? {}),
  }

  return transaction
}

export const convertTransactions = (
  records: Array<any>,
  productUrls?: Dictionary<string>,
  productsInMainTree?: Dictionary<boolean>
): Transaction[] => {
  return records.map((record) =>
    convertTransaction(record, productUrls, productsInMainTree)
  )
}
