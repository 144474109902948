import { FaqQuestion, Faq } from '~/types/faq'

export const convertFaqQuestion = (data: any) => {
  const question: FaqQuestion = {
    id: data.ID,
    name: data.Title,
    url: data.Url,
    text: data.Text,
    plainText: data.PlainText,
    plainSummary: data.PlainSummary,
  }
  return question
}

export const convertFaq = (data: any): Faq => {
  const faq: Faq = {
    id: data.ID,
    name: data.Title,
    url: data.Url,
    key: data.Name,
    text: data.Text ?? '',
  }

  if (data.ChildCategories?.length) {
    faq.children = data.ChildCategories.map((item: any) => convertFaq(item))
  }

  if (data.Questions?.length) {
    faq.questions = data.Questions.map((item: any) => convertFaqQuestion(item))
  }

  return faq
}
