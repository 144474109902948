import { Address, Location } from '~/types/geo'

export const convertAddress = (data: any): Address => {
  return {
    street: data.Street,
    postalCode: data.PostalCode,
    houseNumber: data.HouseNumber,
    houseNumberExtension: data.HouseNumberExtension,
    city: data.City,
    countryCode: data.CountryCode ?? data.CoutryCode,
  }
}

export const convertLocation = (data: any, noDistance = false): Location => {
  return {
    distanceInMeters: noDistance ? undefined : data?.DistanceInMeters,
    globalLocationNumber: data?.GlobalLocationNumber,
    lat: data?.Latitude,
    lng: data?.Longitude,
  }
}
