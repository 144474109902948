import { convertStore } from './store'
import { Preference, Vat } from '@/types/preference'

export const convertPreference = (data: any): Preference | null => {
  if (!data) return null

  const preference: Preference = {
    storeId: data.StoreId
  }

  if (data.Store) {
    preference.store = convertStore({
      ...data.Store,
      Id: data.StoreId,
    })
  }

  return preference
}

export const convertVat = (data: any): Vat | null => {
  if (!data) return null

  return {
    includeVat: data.IncludeVat
  }
}
