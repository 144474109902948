import { Voucher, Coupon } from '~/types/promotion'

export const convertVoucher = (data: any): Voucher => {
  const result: Voucher = {
    id: data.Id,
    code: data.Code,
    paymentAmount: data.BalanceAmount,
    endDate: data.EndDate,
  }

  if (data.Type) {
    result.type = {
      id: data.Type.Id,
      name: data.Type.Description,
    }
    result.partialUsable = data.Type.PartialUsable
  }

  return result
}

export const convertCoupon = (data: any): Coupon => {
  return {
    id: data.Id,
  }
}

export const convertVouchers = (data: any): Voucher[] => {
  return data.map((item: any) => convertVoucher(item))
}
