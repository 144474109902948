import { BrandSuggestion, Suggestion } from '~/types/sugesstion'

export const convertSuggestion = (data: any): Suggestion => {
  return {
    name: data.Title,
    url: data.Url,
  }
}

export const convertBrandSuggestion = (data: any): BrandSuggestion => {
  return {
    ...convertSuggestion(data),
    text: data.Text,
    summary: data.Summary,
  }
}
