import { convertAsposRangeDate } from './time'
import { WebNode } from '@/types/webNode'
import { getValidImage, decodeHtml, inDateRange } from '~/lib/utilities'

export const convertWebNode = (data: any): WebNode => {
  const imgUrl = data.BigImageLink
  const smallImgUrl = data.SmallImageLink
  const node: WebNode = {
    id: data.Id,
    name: data.Description,
    banner: data.BigImageLink,
    description: data.Memo ? decodeHtml(data.Memo) : '',
    code: data.Code,
    img: getValidImage(imgUrl),
    smallImg: getValidImage(smallImgUrl),
    url: data.Url,
    loaded: false,

    isActive: data.Status === 'Active',
    isViewable: true, // decide later
    startDate: convertAsposRangeDate(data.StartDate),
    endDate: convertAsposRangeDate(data.EndDate)
  }

  node.isViewable = node.isActive && inDateRange(node.startDate, node.endDate)

  if (data.Children) {
    // Exclude inactive webnodes here for we never need them
    node.children = data.Children
      .map((child: any) => convertWebNode(child))
      .filter((child: WebNode) => child.isViewable)
    node.loaded = true
  }

  // node.urlLocales = data.UrlLocales

  if (data.Ancestors) {
    node.ancestors = data.Ancestors.map((ancestor: any) =>
      convertWebNode(ancestor)
    )
  }

  return node
}

export const convertWebNodes = (data: any[]) => {
  return data.map((item) => convertWebNode(item))
}
