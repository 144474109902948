import { countBy, Dictionary } from 'ramda'
import { convertVariantProductsJson } from './product.meili'
import { convertBrand } from '~/lib/api/deserializers/brand'
import { convertWebNode } from '~/lib/api/deserializers/webNode'
import { getValidColorHex, trimString, decodeHtml } from '~/lib/utilities'
import { inRange } from '~/lib/utilities/datetime'
import { RatingGroup } from '~/types/common'
import {
  Product,
  ProductAvailabilityInServer,
  ProductDiscount,
  // ProductDeliverySize,
  // ProductDeliveryType,
  ProductDiscountLevel,
  ProductReview,
  ProductSelection,
  ProductStock,
} from '~/types/product'
import project from '~/project.config.js'

interface GetValueOption<T> {
  name: string
  convertValue: (value: string) => T
  defaultValue: T
}

export const VIEWABLE_EXCLUDE_TYPES = new Set(
  project.product?.viewableExcludeTypes ?? []
)

export const checkOrderable = (product: Product) => {
  if (product.isVirtual || !product.isViewable || !product.isActive)
    return false

  return (
    product.isOrderable && (product.maxStockEnabled ? product.inStock : true)
  )
}

export const checkValidInCart = (
  product: Product,
  considerWebNode: boolean = true
) => {
  if (product.isVirtual) return false
  if (considerWebNode && !product.webNode) return false

  return (
    product.isOrderable && (product.maxStockEnabled ? product.inStock : true)
  )
}

export const convertDiscount = (data: any): ProductDiscount => {
  const ticketDescription = data.TicketDescription || data.ticketDescription
  const description = data.Description || data.description
  const startDate = data.StartDate || data.startDate
  const endDate = data.EndDate || data.endDate

  return {
    id: data.Id || data.id,
    name: ticketDescription || description,
    category: (data.DiscountCategory || data.discountCategory)?.toLowerCase(),
    img: data.Images?.[0]?.Url || data.images?.[0]?.url,
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
  }
}

export const convertDiscounts = (discounts: any): ProductDiscount[] => {
  return (
    discounts?.reduce(
      (prev: ProductDiscount[], current: Record<string, any>) => {
        const discount = convertDiscount(current)
        const webshopLabelTypeCode =
          current.WebshopLabelTypeCode || current.webshopLabelTypeCode
        if (
          discount.name &&
          webshopLabelTypeCode !== 'NOLABEL' &&
          inRange(discount.startDate, discount.endDate)
        ) {
          prev.push(discount)
        }
        return prev
      },
      []
    ) ?? []
  )
}

export const convertMoreLess = (
  product: Product,
  moreLessLevels: Array<any>
) => {
  if (!moreLessLevels) return

  product.moreLessLevels = moreLessLevels
    .map(
      (data) =>
        ({
          id: data.Id,
          quantity: data.Quantity,
          priceInclTax: data.DiscountPriceInclTax,
          priceExclTax: data.DiscountPriceExclTax,
          save:
            (product.priceInclTax - data.DiscountPriceInclTax) /
            product.priceInclTax,
        } as ProductDiscountLevel)
    )
    .sort((a: ProductDiscountLevel, b: ProductDiscountLevel) =>
      (a.quantity ?? 0) < (b.quantity ?? 0) ? -1 : 1
    )
}

export const convertStock = (data: any) => {
  return {
    storeId: data.StoreId,
    quantity: data.AvailableQuantity > 0 ? data.AvailableQuantity : 0,
  }
}

export const getFieldValue = function <T>(
  fields: Record<string, string>,
  option: GetValueOption<T>
): T {
  const { name, convertValue, defaultValue } = option
  const value = fields[name.toLowerCase()]
  if (!value) return defaultValue

  return convertValue(value)
}

export const getFieldLowerValue = function <T>(
  fields: Record<string, string>,
  option: GetValueOption<T>
): T {
  const { name, convertValue, defaultValue } = option
  return getFieldValue(fields, {
    name,
    convertValue: (v) => convertValue(v.toLowerCase()),
    defaultValue,
  })
}

export const convertProduct = (data: any, extraFields: any): Product => {
  // Prepare fields convert
  const fields: Dictionary<string> = {}
  if (data.Fields) {
    data.Fields.forEach((field: any) => {
      fields[field.Code.toLowerCase()] = field.Value
    })
  }

  const getValue = <T>(
    name: string,
    convertValue: (value: string) => T,
    defaultValue: T
  ) => {
    return getFieldValue(fields, {
      name,
      convertValue,
      defaultValue,
    })
  }

  const getLowerValue = <T>(
    name: string,
    convertValue: (value: string) => T,
    defaultValue: T
  ) => {
    return getFieldLowerValue(fields, {
      name,
      convertValue,
      defaultValue,
    })
  }

  const getMemoByType = (type: string) => {
    return data.Memos.find(
      (memo: any) =>
        memo.MemoType && memo.MemoType.toLowerCase() === type.toLowerCase()
    )
  }

  const type = data.Type
  const activeWebNodes = data.ActiveWebNodes?.map((item: any) =>
    convertWebNode(item)
  )

  // Build product
  const product: Product = {
    id: data.Id,
    type,
    name: data.OnlineDescription || data.Description,
    url: extraFields?.url,
    number: data.Number,
    numberTrimmed: trimString(data.Number, 6),
    scanCode: data.DefaultScanCode?.Code,
    groupName: data.Group?.Description,

    img: data.Images?.[0]?.Url ?? '',
    gallery: data.Images?.map((img: any, index: number) => ({
      type: 'image',
      order: img.Type === 'Front' ? 0 : index + 1,
      src: img.Url,
    })),
    isViewable:
      (activeWebNodes ? activeWebNodes.length > 0 : true) &&
      !VIEWABLE_EXCLUDE_TYPES.has(type) &&
      (extraFields?.inMainTree ?? true),
    isValidInCart: true, // will decide later
    isActive: data.State === 'Active',
    isVirtual: data.Type === 'Variant', // getLowerValue('HOOFDPRODUCT', (v) => v, null) === 'ja',

    ...convertProductAvailibility(extraFields?.Availability),

    priceInclTax: parseFloat((data.PriceInclTax || 0).toFixed(2)),
    priceExclTax: parseFloat((data.PriceExclTax || 0).toFixed(2)),
    taxRate: data.TaxRate / 100,

    brand: data.Brand && convertBrand(data.Brand),

    unitPerProduct: getValue(
      'QtyPerBaseCompUnitCode',
      (v) => (v ? parseFloat(v) : 0),
      0
    ),
  }

  // webNode
  if (activeWebNodes?.length) {
    product.activeWebNodes = activeWebNodes
    // Note: get deepest webNode
    product.webNode = activeWebNodes[activeWebNodes.length - 1]
  }

  // Convert to client-side isOrderable
  product.isValidInCart = checkValidInCart(product)
  product.isOrderable = checkOrderable(product)

  // Label
  const asposLabel = getLowerValue('webshopbanner', (v) => v, null)
  if (asposLabel) {
    product.label = {
      key: asposLabel,
    }
  }

  // Gallery
  let videoUrl = getValue('VIDEOURL', (v) => v, null)
  if (videoUrl) {
    const match = /watch\?v=(.+)/i.exec(videoUrl)
    if (match) {
      videoUrl = `https://www.youtube.com/embed/${match[1]}`
    }

    product.gallery ??= []
    product.gallery.push({
      type: videoUrl.includes('youtube') ? 'youtube' : 'video',
      order: 999999,
      src: videoUrl,
    })
  }

  // Unit and price
  const unitPriceExclTax = getValue('CompPrice', (v) => parseFloat(v), 0)
  const unit = getValue('BaseCompUnitCode', (v) => v, null)
  if (unitPriceExclTax && unit) {
    product.unit = unit
    product.unitPriceExclTax = unitPriceExclTax
    product.unitPriceInclTax = unitPriceExclTax * (1 + (product.taxRate ?? 0))
  }

  // size
  const size = getValue('ExternalContent', (v) => v, null)
  if (size) {
    product.size = size
  }

  // color hex
  const colorHex =
    getValue('Kleurcode', (v) => v, null) || getValue('Hex', (v) => v, null)
  if (colorHex) {
    product.colorHex = getValidColorHex(colorHex)
  }

  // attention
  const attention = getValue('Webshopattentieregel', (v) => v, null)
  if (attention) {
    product.attention = attention
  }

  // Discounts
  if (data.Discounts?.length) {
    product.discounts = convertDiscounts(data.Discounts)
  }

  // Specification preparation
  if (data.Fields) {
    product.fields = data.Fields.map((data: any) => ({
      code: data.Code,
      label: data.Description,
      value: data.Value,
    }))
  }

  // Pricing
  if (data.Discount) {
    product.oldPriceInclTax = product.priceInclTax
    product.oldPriceExclTax = product.priceExclTax
    product.priceInclTax = data.Discount.PriceInclTax
    product.priceExclTax = data.Discount.PriceExclTax
  }

  // Big contents
  if (data.Memos) {
    const internetMemo = getMemoByType('InternetMemo')
    // const compositionMeno = getMemoByType('Composition')

    product.description = internetMemo?.Text
      ? decodeHtml(internetMemo.Text)
      : ''
    // product.ingredients = compositionMeno?.Text
    //   ? decodeHtml(compositionMeno.Text)
    //   : ''
  }

  if (data.Fields) {
    product.ingredients = data.Fields.find((field: any) => field.Code.toLowerCase() === "ingrediënten")?.Value || ''
    product.usageDescription = data.Fields.find((field: any) => field.Code.toLowerCase() === "gebruik")?.Value || ''
  }

  // Stock
  if (data.StockInfo) {
    product.stock = data.StockInfo.map((stock: any) => convertStock(stock))
  }

  // Brand
  if (
    data.Brand &&
    extraFields &&
    extraFields.BrandMemos &&
    extraFields.BrandMemos.length
  ) {
    product.brand = convertBrand(data.Brand, extraFields)
  }

  // Attached files
  if (extraFields && extraFields.Memos && extraFields.Memos.length) {
    product.files = extraFields.Memos.filter(
      (memo: any) => memo && memo.FileName && memo.Id
    ).map((memo: any) => {
      return {
        fileName: memo.FileName,
        memoId: memo.Id,
      }
    })
    // const usageMeno = getMemoByType('Usage')
    // product.usageDescription = usageMeno?.Text ? decodeHtml(usageMeno.Text) : ''
  }

  if (extraFields && extraFields.MoreLessLevels) {
    convertMoreLess(product, extraFields.MoreLessLevels)
  }

  // review
  const reviewStats = extraFields?.ReviewStats
  if (reviewStats) {
    product.rating = reviewStats.Rating ?? 0
    product.reviewCount = reviewStats.Count ?? 0
  }

  // voucher code
  const voucherCode = getLowerValue('EVLVoucherCode', (v) => v, '')
  if (voucherCode) {
    product.voucherCode = voucherCode
  }

  if (product.isVirtual && extraFields?.VariantProductsJson) {
    product.variants = convertVariantProductsJson(
      extraFields.VariantProductsJson
    )
  }

  return product

  // function convertBouwmaatProduct() {
  //   const safetyLogos = getValue('GEVAARLOGO', (v) => v, null)
  //   product.safetyLogos = safetyLogos ? safetyLogos.split('|') : []

  //   product.deliveryType = getLowerValue(
  //     'DeliveryType',
  //     (v) => v as ProductDeliveryType,
  //     null
  //   )

  //   product.deliverySize = getLowerValue(
  //     'DeliveryCostCat',
  //     (v) =>
  //       v === 'grootgoed'
  //         ? ProductDeliverySize.Large
  //         : ProductDeliverySize.Small,
  //     ProductDeliverySize.Small
  //   )
  // }
}

export const convertProducts = (
  products: Array<any>,
  productUrls: Dictionary<string>,
  extraFields?: Dictionary<any>
): Product[] => {
  return products.map((product) =>
    convertProduct(
      product,
      Object.assign(
        {
          url: productUrls[product.Id],
        },
        extraFields?.[product.Id]
      )
    )
  )
}

export const convertProductSelection = (
  data: any,
  extraFields?: Record<string, any>
): ProductSelection => {
  return {
    id: data.Id,
    productId: data.ProductId,
    product: convertProduct(data.Product, extraFields),
  }
}

export const convertProductSelections = (
  data: any,
  urls: any,
  extraFields?: any
) => {
  return (
    data?.map((item: any) =>
      convertProductSelection(item, {
        url: urls?.[item.ProductId],
        ...(extraFields?.[item.ProductId] ?? {}),
      })
    ) ?? []
  )
}

export const convertProductReview = (
  data: Record<string, any>
): ProductReview => {
  return {
    rating: data.CustomerRating,
    content: data.Content,
    date: new Date(data.CreationDate),
  }
}

export const convertProductReviews = (
  data: Record<string, any>[],
  needRatingGroups = false
) => {
  let totalRating = 0
  const reviews =
    data?.map((review) => {
      const convertedReview = convertProductReview(review)
      totalRating += convertedReview.rating
      return convertedReview
    }) ?? []
  const totalCount: number = reviews.length
  const averageRating = totalCount && totalRating / totalCount
  let ratingGroups: RatingGroup[] = []
  if (needRatingGroups) {
    const obj = countBy((item) => item.rating, reviews)
    ratingGroups = Object.keys(obj).map((key) => ({
      count: obj[key],
      rating: Number(key),
    }))
  }

  return {
    reviews,
    totalCount,
    averageRating: Math.round(averageRating * 10) / 10,
    totalRating,
    ...(needRatingGroups ? { ratingGroups } : {}),
  }
}

export const convertProductStock = (data: any): ProductStock[] => {
  return data.map((store: any) => ({
    storeId: store.StoreId,
    quantity: store.AvailableQuantity > 0 ? store.AvailableQuantity : 0,
    purchaseQuantity:
      store.PurchaseOrderQuantity > 0 ? store.PurchaseOrderQuantity : 0,
  }))
}

export const convertProductAvailibility = (
  data: any
): ProductAvailabilityInServer => {
  const stockQuantity = data?.StockQuantity ?? 0

  return {
    isOrderable: data?.IsOrderable ?? false,
    inStock: data?.HasStock ?? false,
    stockQuantity: stockQuantity > 0 ? stockQuantity : 0,
    maxStockEnabled: data?.MaxStockEnabled ?? false,
    isDeliverable: data?.IsDeliverable ?? false,
    isPickupable: data?.IsPickupable ?? false,
  }
}
