import { dropLast, split } from 'ramda'
import {
  checkOrderable,
  checkValidInCart,
  convertDiscounts,
  getFieldValue,
  VIEWABLE_EXCLUDE_TYPES,
} from './product'
import {
  getValidColorHex,
  isString,
  parseJson,
  trimString,
} from '~/lib/utilities'
import { Product, ProductFacet, ProductSortOption } from '~/types/product'
import { WebNode } from '~/types/webNode'

export const convertVariantProductsJson = (
  json: string,
  parentUrl?: string
): Product[] => {
  const variantProducts = parseJson(json)
  if (Array.isArray(variantProducts) && variantProducts.length) {
    const baseUrl = parentUrl
      ? dropLast(1, split('-')(parentUrl)).join('-')
      : ''
    return variantProducts
      .map((item) => convertVariantProduct(item, baseUrl))
      .sort((a, b) => {
        if (a.priceInclTax === b.priceInclTax) {
          return a.name > b.name ? 1 : -1
        }
        return a.priceInclTax - b.priceInclTax
      })
  }
  return []
}

// products
export const convertProduct = (data: any): Product => {
  const grossPrice = data.GrossPrice ? Number(data.GrossPrice) : 0
  const salePrice = data.SalePrice ? Number(data.SalePrice) : undefined
  const isVirtual =
    data.IsVirtualProduct !== 'False' || data.IsVirtualProductString !== 'False'
  const inStock = data.OutOfStock === 'False'
  // mock
  const stockQuantity = inStock ? 9999 : 0

  const type = data.ProductType

  const product: Product = {
    id: Number(data.ProductId),
    type,
    name: data.Title,
    img: data.ImageUrl,
    number: data.ProductCode,
    numberTrimmed: trimString(data.ProductCode, 6),
    url: data.Url,
    priceInclTax: salePrice ?? grossPrice,
    oldPriceInclTax:
      salePrice && grossPrice > salePrice ? grossPrice : undefined,
    isActive: true, // mock data
    // availability, mock now
    isViewable: !VIEWABLE_EXCLUDE_TYPES.has(type),
    isOrderable: true, // decide later
    isValidInCart: true, // decide later
    inStock: true,
    stockQuantity,
    maxStockEnabled: false,
    isDeliverable: true,
    isPickupable: true,
    isVirtual,
  }

  if (data.BrandName || data.BrandTitle || data.BrandUrl) {
    product.brand = {
      id: Number(data.BrandName),
      name: data.BrandTitle,
      url: data.BrandUrl,
    }
  }

  if (data.EANCode) {
    product.scanCode = data.EANCode
  }

  if (data.Size) {
    product.size = data.Size
  }

  if (data.Discounts) {
    const Discounts = isString(data.Discounts)
      ? parseJson(data.Discounts)
      : data.Discounts
    if (Array.isArray(Discounts) && Discounts.length) {
      product.discounts = convertDiscounts(Discounts)
    }
  }

  if (product.isVirtual && data.VariantProductsJson) {
    product.variants = convertVariantProductsJson(data.VariantProductsJson)
  }

  // webNode
  if (data.ItemWebNode) {
    const webNode = parseJson(data.ItemWebNode) as WebNode
    product.webNode = {
      ...webNode,
      name: webNode.description ?? '',
    }
  }

  // isOrderable
  product.isOrderable = checkOrderable(product)
  product.isValidInCart = checkValidInCart(product, false)

  // fields
  const Fields = data.FieldsJson && parseJson(data.FieldsJson)

  if (Fields && Array.isArray(Fields)) {
    const fields: Record<string, string> = {}
    Fields.forEach((field: any) => {
      fields[field.code.toLowerCase()] = field.value
    })
    const getValue = <T>(
      name: string,
      convertValue: (value: string) => T,
      defaultValue: T
    ) => {
      return getFieldValue(fields, {
        name,
        convertValue,
        defaultValue,
      })
    }

    // color
    const colorHex = getValue('Kleurcode', (v) => v, null)
    if (colorHex) {
      product.colorHex = getValidColorHex(colorHex)
    } else if (data.ColorHex) {
      product.colorHex = getValidColorHex(data.ColorHex)
    }
  }

  return product
}

export const convertProducts = (data: any[]): Product[] => {
  return data.map((item: any) => convertProduct(item))
}

export const convertVariantProduct = (data: any, baseUrl?: string): Product => {
  const product = convertProduct(data)
  if (!product.id) {
    product.id = Number(data.Name) // issue 4648: name = productId
  }
  if (!product.url && baseUrl && product.number) {
    product.url = `${baseUrl}-${product.number}`
  }
  product.variantType = data.ConnectionType
  return product
}

//  facets
export const convertFacet = (data: any): ProductFacet => {
  const facet = {
    field: data.Name,
    label: data.Title,
    values:
      data.Distribution?.map((item: any) => ({
        label: item.Key,
        value: item.Key,
        count: item.Value,
        checked: false,
      })) ?? [],
  }

  return facet
}

export const convertFacets = (data: any[]): ProductFacet[] => {
  return data.reduce((prev: ProductFacet[], current) => {
    if (current.Distribution?.length) {
      prev.push(convertFacet(current))
    }
    return prev
  }, [])
}

// sort option
export const convertSortOption = (data: any): ProductSortOption => {
  return {
    label: data.Title,
    field: data.Name,
    order: data.Order,
    value: `${data.Name}:${data.Order}`,
  }
}
